<route>
{
  "meta": {
    "auth": "withdrawDetail"
  }
}
</route>

<template>
  <div>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="storeName" label="申请人员"> </el-table-column>
        <el-table-column align="center" prop="money" label="申请提现金额"> </el-table-column>

        <el-table-column align="center" label="银行账号信息">
          <template slot-scope="scope">
            <div>开户人姓名: {{ scope.row.bankAccountInfoVO.accountName }}</div>
            <div>收款银行: {{ scope.row.bankAccountInfoVO.accountBank }}</div>
            <div>银行账号: {{ scope.row.bankAccountInfoVO.accountNumber }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="提现时间">
          <template slot-scope="scope">
            <div>{{ scope.row.applyTime | formatTime }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="到账时间">
          <template slot-scope="scope">
            <div>{{ scope.row.collectedTime | formatTime }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],

  data() {
    return {
      searchValue: '',
      list: [],
      vendorDetail: {}
    }
  },
  methods: {
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$api.financial
        .financial('getWithdrawalDetail', params, this.$route.query.id)
        .then(res => {
          this.vendorDetail = res
          this.list = res.detail.records
          this.total = parseInt(res.detail.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  div {
    line-height: 30px;
    .intro {
      display: inline-block;
      width: 100px;
      color: #999;
    }
  }
}
</style>
